import React, { useContext, useEffect, useState } from 'react';
import { Grid, Box, Tooltip, useTheme } from '@mui/material';
import { ToastDataContext, AuthDataContext } from 'contexts';
import addressService from 'services/address-service';
import testbuyService from 'services/testbuy-service';
import sellerService from 'services/seller-service';
import actionService from 'services/action-service';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';
import { Loading, LoadingButton, CustomDialog, CustomDialogContent, Chip } from 'components';
import StripeCC from 'pages/Common/StripeCC';
import { useTracking } from 'react-tracking';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import PrivacyTerms from './PurchasingTerms';
import OrderInfo from './OrderInfo';
import SuccessDialog from './SuccessDialog';
import DeliveryAddress from './DeliveryAddress';

const PurchaseTestBuy = ({ onClose, offerId, sellerId, enforcementId }) => {
  const { account, tenant } = useContext(AuthDataContext);
  const { setToast } = useContext(ToastDataContext);
  const [asinToBuy, setAsinToBuy] = useState('');
  const [offer, setOffer] = useState(null);
  const [seller, setSeller] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [cardOnFile, setCardOnFile] = useState(false);
  const [credits, setCredits] = useState(0);
  const [fee, setFee] = useState(0);
  const [creditCost, setCreditCost] = useState(0);
  const [hasEnoughCredits, setHasEnoughCredits] = useState(false);
  const [privateRecipient, setPrivateRecipient] = useState(true);
  const [loadingExistance, setLoadingExistance] = useState(true);
  const [offerStillExists, setOfferStillExists] = useState(null);
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const processCreditsFeesData = (dataToProcess) => {
    const creditsFeeData = dataToProcess.simulatePurchase;
    setCreditCost(creditsFeeData.creditsRequired);

    if (creditsFeeData.priceWithFee > 0) {
      const additionalFee = parseFloat(creditsFeeData.priceWithFee) - parseFloat(offer.price);
      setFee(additionalFee);
    }
    if (dataToProcess.simulatePurchase.creditsRequired > 0) {
      setHasEnoughCredits(true);
    }
  };

  // calculates credits/fees
  const GET_CREDITS_FEES = gql`
    mutation GetCreditsFeesQuery($accountId: Int, $value: Float) {
      simulatePurchase(accountId: $accountId, value: $value) {
        creditsRequired
        priceWithFee
      }
    }
  `;

  const [getCreditsFees] = useMutation(GET_CREDITS_FEES, {
    onCompleted: processCreditsFeesData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const DOES_OFFER_EXIST_QUERY = gql`
    query DoesOfferCurrentlyExist($asin: String!, $sellerId: String!) {
      doesOfferCurrentlyExist(asin: $asin, sellerId: $sellerId)
    }
  `;

  const processOfferExistData = (dataToProcess) => {
    setLoadingExistance(false);
    setOfferStillExists(dataToProcess.doesOfferCurrentlyExist);
  };

  const [fetchExistData] = useLazyQuery(DOES_OFFER_EXIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processOfferExistData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
      setLoadingExistance(false);
    },
  });

  const processCreditsData = (result) => {
    setCredits(result.getAccountCredits[0]?.creditBalance);
  };

  const GET_CREDITS = gql`
    query GetAccountCredits($accountId: Int) {
      getAccountCredits(accountId: $accountId) {
        creditBalance
      }
    }
  `;

  const [fetchCredits] = useLazyQuery(GET_CREDITS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: (result) => processCreditsData(result),
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const getAddresses = () => {
    addressService
      .getAddresses()
      .then((result) => {
        let filteredAddresses = result.filter((r) => r.addressTypeId !== 3);
        filteredAddresses = filteredAddresses.map((address) => ({
          ...address,
          label: address.description ?? address.addressLine1,
        }));
        setAddresses(filteredAddresses);
      })
      .catch((err) => {
        setToast({ type: 'error', message: err.message });
      });
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const addressResult = await addressService.getAddresses();
      let filteredAddresses = addressResult.filter((r) => r.addressTypeId !== 3);
      filteredAddresses = filteredAddresses.map((address) => ({
        ...address,
        label: address.description ?? address.addressLine1,
      }));
      setAddresses(filteredAddresses);

      const sellerResult = await sellerService.getSeller({ id: sellerId });
      setSeller(sellerResult);
      const offerResult = await sellerService.getOffer({ offerId });
      setOffer(offerResult);
      setAsinToBuy(offerResult.asin);
      fetchExistData({ variables: { sellerId, asin: offerResult.asin } });
      fetchCredits({ variables: { accountId: account.id } });
      getCreditsFees({
        variables: {
          accountId: account.id,
          value: parseFloat(offerResult.price) + parseFloat(offerResult.shippingPrice),
        },
      });
    } catch (err) {
      setToast({ type: 'error', message: err.message });
    } finally {
      setLoading(false);
    }
  };

  const closeSuccessDialog = (close) => {
    setSuccessDialogOpen(close);
    onClose(true);
  };

  const handleAddressSelect = (addressId) => {
    setSelectedAddress(addressId);
  };

  useEffect(() => {
    if (tenant.tenant !== 'ipsecure') {
      setTermsAgreed(true);
    }
    loadData();
  }, []);

  const onPlaceOrder = async () => {
    setProcessing(true);

    // If no enforcementId is present, create one
    let enforcementIdToUse = enforcementId;
    if (!enforcementIdToUse) {
      const result = await actionService.addToEnforcementQueue({
        offers: [
          {
            sellerId: offer.sellerId,
            asin: offer.asin,
            offerId,
          },
        ],
      });
      if (result && result.id !== null) {
        enforcementIdToUse = result.id;
      } else {
        setToast({ type: 'error', message: 'Sorry an error has occurred' });
        setProcessing(false);
      }
    }
    if (enforcementIdToUse) {
      testbuyService
        .createAmazonOrder(
          asinToBuy,
          1,
          sellerId,
          parseInt(selectedAddress, 10),
          offerId,
          parseInt(enforcementIdToUse, 10),
          privateRecipient,
        )
        .then(() => {
          trackEvent({
            tag: 'testbuy',
            description: `Purchased test buy of ${asinToBuy} from ${seller.name} (${sellerId})`,
          });
          setSuccessDialogOpen(true);
        })
        .catch((err) => {
          setToast({ type: 'error', message: err.message });
          console.log(err);
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  const handleAddressClose = () => {
    getAddresses();
    setSelectedAddress(addresses[addresses.length - 1].id);
  };

  const purchasingEnabled = !(tenant.tenant !== 'ipsecure'
    ? !selectedAddress || !(!cardOnFile || !hasEnoughCredits)
    : !termsAgreed || !selectedAddress || !(!cardOnFile || !hasEnoughCredits));

  return (
    <CustomDialog
      open
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="md"
      title="Make Test Buy"
      subtitle="Shipment Details"
      sx={{
        '& .MuiDialog-paper': {
          flexGrow: 1,
          height: '100%',
          minHeight: `calc(100vh - 150px)`,
          maxHeight: `calc(100vh - 150px)`,
          minWidth: `calc(100vw - 150px)`,
          maxWidth: `calc(100vw - 150px)`,
        },
      }}
      disableBackdropClick
      content={
        <CustomDialogContent>
          {loading && (
            <Grid container spacing={2} sx={{ mb: '100px' }}>
              <Grid item xs={12}>
                <Loading />
              </Grid>
            </Grid>
          )}
          {!loading && offer && (fee > 0 || creditCost > 0) && seller && (
            <>
              <Grid container xs={12} item>
                <OrderInfo
                  product={offer}
                  hasEnoughCredits={hasEnoughCredits}
                  creditBalance={credits}
                  creditCost={creditCost}
                  fee={fee}
                  seller={seller}
                />
              </Grid>
              <Grid container item xs={12}>
                <DeliveryAddress
                  onClose={handleAddressClose}
                  addresses={addresses}
                  setNewAddressId={handleAddressSelect}
                  setTermsAgreed={setTermsAgreed}
                  privateRecipient={privateRecipient}
                  setPrivateRecipient={setPrivateRecipient}
                />
              </Grid>
            </>
          )}
          {!loading && !hasEnoughCredits && (
            <Grid container item xs={12} sx={{ p: 2, m: 1.5 }}>
              <StripeCC setCardOnFile={(c) => setCardOnFile(c)} size={4} />
            </Grid>
          )}
          {!loading && <SuccessDialog open={successDialogOpen} onClose={closeSuccessDialog} />}
        </CustomDialogContent>
      }
      actions={
        <>
          {!loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              {tenant.tenant !== 'ipsecure' && <Box />}
              {tenant.tenant === 'ipsecure' && (
                <PrivacyTerms termsAgreed={termsAgreed} setTermsAgreed={setTermsAgreed} />
              )}
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                {loadingExistance && (
                  <Chip
                    sx={{ fontSize: '14px', fontWeight: 700, py: 1, px: 2 }}
                    label={
                      <Box sx={{ display: 'flex', gap: 1, fontSize: '13px' }}>
                        Checking Availability <Loading sx={{ opacity: 0.67 }} small />
                      </Box>
                    }
                    backgroundColor={theme.palette.chips.yellow.backgroundColor}
                    color={theme.palette.chips.yellow.color}
                  />
                )}
                {!loadingExistance && !offerStillExists && (
                  <Chip
                    sx={{ fontSize: '14px', fontWeight: 700, py: 1, px: 2 }}
                    label="Offer No Longer Available"
                    backgroundColor={theme.palette.chips.red.backgroundColor}
                    color={theme.palette.chips.red.color}
                  />
                )}
                {!loadingExistance && offerStillExists && (
                  <Chip
                    sx={{ fontSize: '14px', fontWeight: 500, py: 1, px: 2 }}
                    label="Offer Still Available"
                    backgroundColor={theme.palette.chips.green.backgroundColor}
                    color={theme.palette.chips.green.color}
                  />
                )}
                <LoadingButton
                  disabled={!purchasingEnabled || !offerStillExists}
                  loading={processing}
                  onClick={() => onPlaceOrder()}
                  data-cy="place_order_button"
                >
                  {/* check if enough credits exist for order otherwise disable button? */}
                  {loading ? 'Processing...' : 'Place Order'}
                  {purchasingEnabled && (
                    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Tooltip title="Currently, Test Buys are not returnable through the app. If you'd like additional information, please contact Customer Service.">
                        <InfoIcon sx={{ width: '15px', height: '15px', ml: 1 }} />
                      </Tooltip>
                    </Box>
                  )}
                </LoadingButton>
              </Box>
            </Box>
          )}
        </>
      }
    />
  );
};

PurchaseTestBuy.propTypes = {
  onClose: PropTypes.func.isRequired,
  sellerId: PropTypes.string.isRequired,
  offerId: PropTypes.string.isRequired,
  enforcementId: PropTypes.string,
};

PurchaseTestBuy.defaultProps = {
  enforcementId: null,
};

export default PurchaseTestBuy;
