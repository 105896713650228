import React, { useState, useEffect, useContext } from 'react';
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  useTheme,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@mui/material';
import { useMutation, gql, useQuery } from '@apollo/client';
import { ToastDataContext, AuthDataContext } from 'contexts';
import {
  TabPanel,
  LoadingButton,
  NonScrollable,
  CustomDialog,
  CustomDialogContent,
} from 'components';
import { cloneDeep } from 'lodash';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import EmptyState from 'pages/Common/EmptyState';
import IPNumbersRow from './IPNumbersRow';

const IPNumbers = () => {
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(0);
  const { setToast } = useContext(ToastDataContext);
  const { account, updateAccount } = useContext(AuthDataContext);
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [IPNumber, setIPNumber] = useState('');
  const [selectedType, setSelectedType] = useState('copyright');
  const [formErrors, setFormErrors] = useState([]);
  const [copyrights, setCopyrights] = useState([]);
  const [patents, setPatents] = useState([]);
  const [trademarks, setTrademarks] = useState([]);
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const theme = useTheme();

  const ADD_ACCOUNT_IP = gql`
    mutation AddAccountIP($type: String!, $number: String, $notes: String) {
      addAccountIP(type: $type, number: $number, notes: $notes)
    }
  `;

  const UPDATE_ACCOUNT_IP = gql`
    mutation UpdateAccountIP($id: Int!, $number: String, $notes: String, $type: String!) {
      updateAccountIP(id: $id, number: $number, notes: $notes, type: $type)
    }
  `;

  const REMOVE_ACCOUNT_IPS = gql`
    mutation RemoveAccountIPs($ids: [Int]!, $type: String!) {
      removeAccountIPs(ids: $ids, type: $type)
    }
  `;

  const GET_ACCOUNT_IPS = gql`
    query GetAccountIPs {
      getAccountCopyrights {
        id
        accountId
        createdDate
        updatedDate
        notes
        number
      }
      getAccountPatents {
        id
        accountId
        createdDate
        updatedDate
        notes
        number
      }
      getAccountTrademarks {
        id
        accountId
        createdDate
        updatedDate
        notes
        number
      }
    }
  `;

  const { data, refetch } = useQuery(GET_ACCOUNT_IPS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const [addAccountIP] = useMutation(ADD_ACCOUNT_IP, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const [updateAccountIP] = useMutation(UPDATE_ACCOUNT_IP, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const [removeAccountIP] = useMutation(REMOVE_ACCOUNT_IPS, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const UPDATE_ACCOUNT_CONTACT_MUTATION = gql`
    mutation UpdateAccount($id: ID!, $contactName: String, $contactEmail: String) {
      updateAccount(id: $id, contactName: $contactName, contactEmail: $contactEmail) 
    }
  `;

  const [updateAccountContactInfo] = useMutation(UPDATE_ACCOUNT_CONTACT_MUTATION, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const resetForm = () => {
    setDescription('');
    setIPNumber('');
    setSelectedType('');
  };

  const getAccountNumbers = (selectedTab) => {
    if (selectedTab === 0) {
      setSelectedType('copyright');
    } else if (selectedTab === 1) {
      setSelectedType('trademark');
    } else {
      setSelectedType('patent');
    }
  };

  useEffect(() => {
    if (data) {
      setLoading(false);
      setCopyrights(cloneDeep(data.getAccountCopyrights));
      setPatents(cloneDeep(data.getAccountPatents));
      setTrademarks(cloneDeep(data.getAccountTrademarks));
      getAccountNumbers(tab);
    }
  }, [data]);

  useEffect(() => {
    if (account) {
      setContactEmail(account.contactEmail);
      setContactName(account.contactName);
    }
  }, [account])

  const changeTab = (value) => {
    setTab(value);
    getAccountNumbers(value, data);
    setSaved(false);
  };

  const onClose = () => {
    resetForm();
    setOpen(false);
  };

  const onSubmit = () => {
    try {
      setLoading(true);
      addAccountIP({
        variables: {
          type: selectedType,
          number: IPNumber,
          notes: description,
        },
        onCompleted: () => {
          setLoading(false);
          refetch();
        },
      });
    } catch (err) {
      setToast({ type: 'error', message: err.message });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const validateForm = () => {
    const errors = [];
    if (description === '') {
      errors.push({ description: 'A description is needed' });
    }
    if (IPNumber === '') {
      errors.push({ IPNumber: 'An IP Number is needed' });
    }
    if (selectedType === '') {
      errors.push({ selectedType: 'Please choose an IP Type' });
    }

    if (errors.length === 0) {
      onSubmit();
    } else {
      setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    }
  };

  const editIPNumber = (id, newDescription, newNumber, newType) => {
    try {
      setLoading(true);
      updateAccountIP({
        variables: {
          id,
          type: newType,
          notes: newDescription,
          number: newNumber,
        },
        onCompleted: () => {
          setLoading(false);
          refetch();
        },
      });
    } catch (err) {
      setToast({ type: 'error', message: err.message });
    } finally {
      setLoading(false);
    }
  };

  const removeIPNumbers = (ids, IPType) => {
    try {
      setLoading(true);
      removeAccountIP({
        variables: {
          ids,
          type: IPType,
        },
        onCompleted: () => {
          setLoading(false);
          refetch();
        },
      });
    } catch (err) {
      setToast({ type: 'error', message: err.message });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = () => {
    try {
      setSaving(true);
      updateAccountContactInfo({
        variables: {
          id: account.id,
          contactName,
          contactEmail,
        },
        onCompleted: () => {
          setSaving(false);
          setSaved(true);
          updateAccount();
        },
      });
    } catch (err) {
      setFormErrors({ formError: err.message });
    } finally {
      setSaving(false);
    }
  }

  return (
    <NonScrollable
      sx={{
        pt: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        overflow: 'clip',
        width: '100%',
      }}
    >
      <Box
        sx={{
          height: '100%',
          overflowY: 'auto',
        }}
      >
        {!loading && (
          <>
            <Box
              sx={{
                pl: 4,
                mt: -2,
                pt: 2,
                mb: 2,
                borderRadius: theme.borderRadius,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '37px' }}>
                <Box>
                  <Tabs value={tab} onChange={(_event, value) => changeTab(value)}>
                    <Tab label="Copyrights" data-cy="copyrights_tab" />
                    <Tab label="Trademarks" data-cy="trademarks_tab" />
                    <Tab label="Patents" data-cy="patents_tab" />
                    <Tab label="Violation Contact Info" data-cy="contact_info_tab" />
                  </Tabs>
                </Box>
                {(tab === 0 && copyrights.length > 0 || tab === 1 && trademarks.length > 0 || tab === 2 && patents.length > 0) && (
                  <Box>
                    {tab < 3 && (
                      <Button
                        disabled={loading}
                        variant="outlined"
                        startIcon={<AddIcon fill={theme.palette.primary.main} />}
                        onClick={() => setOpen(true)}
                        data-cy="add_number_button"
                      >
                        {tab === 0 && 'Add Copyright Number'}
                        {tab === 1 && 'Add Trademark Number'}
                        {tab === 2 && 'Add Patent Number'}
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            <TabPanel value={tab} index={0}>
              {copyrights.length > 0 && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="30%">Description</TableCell>
                        <TableCell width="50%">Number</TableCell>
                        <TableCell width="15%">Last Updated</TableCell>
                        <TableCell width="5%" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {copyrights.map((n) => (
                        <IPNumbersRow
                          key={n.id}
                          loading={loading}
                          editIPNumber={editIPNumber}
                          selectedType={'copyright'}
                          removeIPNumbers={removeIPNumbers}
                          number={n}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {copyrights.length === 0 && (
                <EmptyState
                  content="Store Copyright numbers here for easy access when creating enforcements."
                  button={
                    <Button
                      disabled={loading}
                      onClick={() => setOpen(true)}
                      startIcon={<AddIcon style={{ fill: theme.palette.greys.white }} />}
                      data-cy="add_ip_number_button"
                    >
                      Add Copyright number
                    </Button>
                  }
                />
              )}
            </TabPanel>
            <TabPanel value={tab} index={1}>
              {trademarks.length > 0 && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="30%">Description</TableCell>
                        <TableCell width="50%">Number</TableCell>
                        <TableCell width="15%">Last Updated</TableCell>
                        <TableCell width="5%" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {trademarks.map((n) => (
                        <IPNumbersRow
                          key={n.id}
                          loading={loading}
                          editIPNumber={editIPNumber}
                          selectedType={'trademark'}
                          removeIPNumbers={removeIPNumbers}
                          number={n}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {trademarks.length === 0 && (
                <EmptyState
                  content="Store Trademark numbers here for easy access when creating enforcements."
                  button={
                    <Button
                      disabled={loading}
                      onClick={() => setOpen(true)}
                      startIcon={<AddIcon style={{ fill: theme.palette.greys.white }} />}
                      data-cy="add_ip_number_button"
                    >
                      Add Trademark number
                    </Button>
                  }
                />
              )}
            </TabPanel>
            <TabPanel value={tab} index={2}>
              {patents.length > 0 && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="30%">Description</TableCell>
                        <TableCell width="50%">Number</TableCell>
                        <TableCell width="15%">Last Updated</TableCell>
                        <TableCell width="5%" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patents.map((n) => (
                        <IPNumbersRow
                          key={n.id}
                          loading={loading}
                          editIPNumber={editIPNumber}
                          selectedType={'patent'}
                          removeIPNumbers={removeIPNumbers}
                          number={n}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {patents.length === 0 && (
                <EmptyState
                  content="Store Patent numbers here for easy access when creating enforcements."
                  button={
                    <Button
                      disabled={loading}
                      onClick={() => setOpen(true)}
                      startIcon={<AddIcon style={{ fill: theme.palette.greys.white }} />}
                      data-cy="add_ip_number_button"
                    >
                      Add Patent number
                    </Button>
                  }
                />
              )}
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <Box sx={{ mb: 4, fontSize: '14px', color: 'greys.silver' }}>
                The contact information below will be the default information filled in for Seller IP violations and ASIN IP violations. 
              </Box>
              <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                <TextField
                  disabled={loading}
                  data-cy="ip_contact_name"
                  label="Contact Name"
                  autoComplete="no"
                  type="text"
                  value={contactName || ''}
                  onChange={(e) => setContactName(e.target.value)}
                  sx={{ width: '40ch' }}
                />
                <TextField
                  disabled={loading}
                  data-cy="ip_contact_email"
                  label="Contact Email"
                  autoComplete="no"
                  type="text"
                  value={contactEmail || ''}
                  onChange={(e) => setContactEmail(e.target.value)}
                  sx={{ width: '40ch' }}
                />
                <Box>
                  <LoadingButton
                    sx={{ mt: 1 }}
                    loading={saving}
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                    data-cy="profile_update"
                  >
                    Update Contact Info
                  </LoadingButton>
                </Box>
                {saved && (
                  <Box sx={{mt: 1, color: 'success.main', fontSize: '14px'}}>
                    Contact Info saved
                  </Box>
                )}
              </Box>
            </TabPanel>
          </>
        )}
      </Box>
      <CustomDialog
        onClose={onClose}
        open={open}
        title={
          copyrights.length === 0 && patents.length === 0 && trademarks.length === 0
            ? 'Add a new IP Number'
            : `Add ${selectedType.charAt(0).toUpperCase() + selectedType.slice(1)} Number`
        }
        maxWidth="sm"
        content={
          <CustomDialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'space-between',
                alignItems: 'left',
              }}
            >
              <TextField
                required
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                value={description}
                label="Description"
                data-cy="description_field"
                error={Boolean(formErrors.description)}
                helperText={formErrors.description}
              />
              <TextField
                required
                onChange={(e) => setIPNumber(e.target.value)}
                value={IPNumber}
                placeholder="IP Number"
                label="IP Number"
                data-cy="ip_number_field"
                error={Boolean(formErrors.IPNumber)}
                helperText={formErrors.IPNumber}
              />
              <Box>
                <FormControl error={Boolean(formErrors.selectedType)}>
                  <Typography
                    sx={{ fontSize: '16px', fontWeight: '600', mb: '8px', lineHeight: '16px' }}
                  >
                    IP Type *
                  </Typography>
                  <Select
                    sx={{ minWidth: '15vw', maxWidth: '15vw' }}
                    value={selectedType}
                    onChange={(e) => {
                      setSelectedType(e.target.value);
                    }}
                    displayEmpty
                    data-cy="ip_type_select"
                  >
                    <MenuItem disabled value="">
                      Choose IP Type
                    </MenuItem>
                    <MenuItem value={'copyright'} data-cy="copyright">Copyright</MenuItem>
                    <MenuItem value={'patent'} data-cy="patent">Patent</MenuItem>
                    <MenuItem value={'trademark'} data-cy="trademark">Trademark</MenuItem>
                  </Select>
                  {formErrors.selectedType && (
                    <FormHelperText error>{formErrors.selectedType}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Box>
          </CustomDialogContent>
        }
        actions={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2,
              }}
            >
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton loading={loading} disabled={loading} onClick={validateForm} data-cy="add_ip_button">
                Add
              </LoadingButton>
            </Box>
          </Box>
        }
      />
    </NonScrollable>
  );
};

export default IPNumbers;
